<template>
    <div class="about">
        <div class="about-title background">
            <div class="content">关于我们</div>
        </div>
        <div class="about-menu">
            <div class="content menu-list">
                <div class="menu-item" :class="{active:active==0}" @click="changeActive(0)">网站地图</div>
<!--                <div class="menu-item" :class="{active:active==4}" @click="changeActive(1)">数据汇交</div>-->

                <div class="menu-item" :class="{active:active==2}" @click="changeActive(2)">发展历程</div>
                <div class="menu-item" :class="{active:active==3}" @click="changeActive(3)">管理规范</div>
                <div class="menu-item" :class="{active:active==1}" @click="changeActive(4)">依托单位</div>
                <div class="menu-item" :class="{active:active==5}" @click="changeActive(5)">合作联系</div>
            </div>
            
        </div>
        <div class="about-content">
            <router-view/>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
export default {
    setup(){
            const active = ref(0)

            const router = useRouter();
            const route = useRoute();

            

            function changeActive(index){
                // active.value = index;
                switch(index){
                    case 0:
                        router.push({name: 'Entry', path: '/entry'});
                        break;
                    case 1:
                        router.push({name: 'Data', path: '/data'});
                        break;
                    case 2:

                        router.push({name: 'History', path: '/history'});
                        break;
                    case 3:
                        router.push({name: 'Manage', path: '/manage'});
                        break;
                    case 4:
                        router.push({name: 'Dependent', path: '/dependent'});
                        break;
                    case 5:
                        router.push({name: 'Contact', path: '/contact'});
                        break;
                }
            }



            return {active, changeActive}
    },
    watch:{
        $route:{
            handler:function(newVal, oldVal){
                this.updateActive(newVal)
            },
            deep: true
        }
    },
    mounted(){
        this.updateActive(this.$route);
    },
    methods:{
        updateActive(newVal){
                if(newVal.path.indexOf('/entry') >= 0){
                    this.active = 0;
                }else if(newVal.path.indexOf('/dependent') >= 0){
                    this.active = 1;
                }else if(newVal.path.indexOf('/history') >= 0){
                    this.active = 2;
                }else if(newVal.path.indexOf('/manage') >= 0){
                    this.active = 3;
                }else if(newVal.path.indexOf('/data') >= 0){
                    this.active = 4;
                }else if(newVal.path.indexOf('/contact') >= 0){
                    this.active = 5;
                } 
        }
    }
}

</script>

<style lang="less" scoped>
.about{
    width: 100%;
    .about-title{
        background-image: url('../../../public/static/img/about.png');
        height: 150px;
        display: flex;
        align-items: center;
        color: #fff;
        font-family: SourceHanSansCN-Medium;
        font-size: 40px;
        color: #FFFFFF;
    }
    .about-menu{
        background: #0A62CA;
        .menu-list{
            display: flex;
            flex-direction: row;
        }
        .menu-item{
            cursor: pointer;
            color: #fff;
            font-family: SourceHanSansCN-Medium;
            font-size: 18px;
            color: #FFFFFF;
            line-height: 50px;
            padding-left: 36px;
            padding-right: 36px;
        }
        .menu-item.active{
            background: #fff;
            color: #0A62CA;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
        }
    }
    .about-content{
        width: 100%;
        background: #fff;
    }
}
</style>
