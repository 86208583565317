
<template>
    <div class="container">
        <div class="banner">
        	使用申请（考虑使用后台页面）
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
export default {
    setup(){
            const active = ref(0)

            const router = useRouter();
            const route = useRoute();

            


            return {active}
    },
    watch:{

    },
    mounted(){

    },
    methods:{

    }
}

</script>

<style lang="less" scoped>

</style>
