<template>
     <div class="dependent about-container">
          <div style="display: flex;justify-content: center;">
               <div style="width: 1200px;display: flex;">
                    <div class="content">
                         <div class="about-box">
                              <h3 class="about-title">机构介绍内容</h3>
                              <p class="about-content">
                                   广东省科技基础条件平台中心（以下简称平台中心）由原“广东省计算中心”更名而成，公益二类事业单位，直属广东省科学技术厅领导，接受国家科技基础条件平台中心的业务指导。主要任务：承担科技基础条件平台的规范标准、管理方式、运行状况和问题研究；建设运行全省科技资源共享网；承担重大项目和重大工程的基本计算任务；开展公共信息工程评测，公共计算领域方法研究；开展新兴信息技术的研究与应用推广。<br/>
                                   <br/>
                              </p>
                              <p class="about-content">
                                   平台中心拥有130多名科技管理、技术研发、科技服务、教育培训等方面的专业技术人员，拥有广东省高性能计算重点实验室、广东省服务计算工程技术研究开发中心、广东省科技资源共享与服务创新工程技术研究中心和广东省突发事件应急信息技术研究中心等省级科研基地。平台中心已建设广东省科技资源共享服务平台、高性能计算平台、信息系统测评实验室（具备CMA、CNAS资质）等技术研发和服务平台，是广东省科技基础条件平台建设促进会、广东省专业镇发展促进会、广东省计算机学会的挂靠单位。<br/>
                                   <br/>
                              </p>
                              <p class="about-content">
                                   历经四十多年的沉淀，平台中心承接了3000多项信息化项目，在信息系统服务链（规划-咨询-监理-研发-测评-验收-运维）、云计算研究与应用、大数据技术研究、区域经济研究与服务、高性能计算研究与服务、科技成果转化、信息技术人才培养、政府委托任务等方面具有独特优势。<br/>
                              </p>
                         </div>
                    </div>
                    <!--
                    <div class="center" style="padding-top: 50px;">
                         <img src="@static/img/about-manage1.png" style="width: 394px;height: 338px;"/>
                    </div>
                    -->
               </div>

          </div>

          <div class="manage2 background"></div>

     </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');
.dependent{
     @media screen and (max-width:1440px){
          .manage2{
               background-image: url('../../../public/static/img/about-keyanpingtai.png');
               background-size: contain;
               background-position: center;
               height: 745px;
          }
          .manage3{
               background-image: url('../../../public/static/img/about-jigou2.png');
               height: 469px;
               background-size: contain;
               background-position: center;
          }
     }
     @media screen and (min-width:1441px){
          .manage2{
               background-image: url('../../../public/static/img/about-keyanpingtai.png') ;
               background-size: contain;
               background-position: center;
               height: 745px;
          }
          .manage3{
               background-image: url('../../../public/static/img/about-jigou2.png') ;
               background-size: contain;
               background-position: center;
               height: 468px;
          }
     }
}
</style>
