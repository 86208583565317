
<template>
    <div class="container">
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection"  width="55"/>-->
<!--            <el-table-column type="index" label="序号" width="80"/>-->
            <el-table-column prop="attachName" label="文件名" width="550">
                <template #default="scope">
                    <a :href="'/core/base/c/download/file?attachId=' + scope.row.attachInfoId"  class="down-link" target="_blank">{{scope.row.attachName}}</a>
                </template>
            </el-table-column>
            <el-table-column prop="attachSize" label="文件大小" width="120">
                <template #default="scope">
                    {{toFileSize(scope.row.attachSize)}}
                </template>
            </el-table-column>
            <!-- <el-table-column prop="attachDesc" label="文件描述" /> -->
            <el-table-column prop="createTime" label="上传时间" width="200"/>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRouter, useRoute} from 'vue-router'

import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '@/components/common/Pager'

export default {
    components: {ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},

    setup(){
        const {proxy} = getCurrentInstance();

        const currentPage = ref(1);
        const loading = ref(false);
        const tablePager = ref({
            totalPage: 1,
            total: 1,
            page: 1,
            list: []
        })

        function getDownloadList(){ //获取下载专区附件信息
            const url = '/core/base/a/attach/filelist?relateType=SCIENCE-DATA-EXCHANGE-FILE&relateId=SCIENCE-DATA-EXCHANGE-FILE';
            // const url = '/core/base/a/attach/filelist?relateType=CPT-DOWNLOAD-ZONE&relateId=CPT-DOWNLOAD-ZONE';
            loading.value = true;

            let param = {
                limit: 10,
                offset: (currentPage.value-1)*10,

            }
            proxy.$http.post(url, param).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            getDownloadList();
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }

        const kb = 1024;
        const mb = 1024 * kb;
        const gb = 1024 * mb;
        function toFileSize(size){  //文件大小转化
            if(size){
                if(size >= gb){
                    return (size/gb).toFixed(2) + " GB";
                }else if(size >= mb){
                    return (size/mb).toFixed(2) + " MB";
                }else if(size >= kb){
                    return (size/kb).toFixed(2) + " KB";
                }else{
                    return size + "B";
                }
            }
        }

        onMounted(()=>{
            getDownloadList();
        })


        return {tablePager, pageChange, handleSelectionChange, toFileSize, currentPage}
    },
    watch:{

    },
    mounted(){

    },
    methods:{

    }
}

</script>

<style lang="less" scoped>
.container{
    margin-bottom: 30px;
}
.down-link{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #007EEC;
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 12px;
     max-width: 440px;
    overflow: hidden;
    text-decoration: none;
}
</style>
